<template>
  <div class="card">
    <div class="card-header header-elements-inline">
      <legend class="font-weight-semibold text-uppercase font-size-sm">
        <i class="icon-file-text2"></i>
        Enter Jobworker information
      </legend>

      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">

      <div class="form-group row">
        <label class="col-md-2 col-form-label">Name:</label>
        <div class="col-md-10">
          <input type="text" id="txtname" class="form-control" placeholder="Jobworker Name" v-if="jobworker" v-model="jobworker.name">
        </div>
      </div>


      <div class="form-group row">
        <label class="col-md-2 col-form-label">Street:</label>
        <div class="col-md-10">
          <textarea rows="3" class="form-control" placeholder="Enter address here" v-if="jobworker" v-model="jobworker.street"></textarea>
        </div>
      </div>


      <div class="form-group row">
        <label class="col-md-2 col-form-label">City:</label>
        <div class="col-md-7">
          <input type="text" class="form-control" placeholder="City" maxlength="50" autocomplete="off" v-if="jobworker" v-model="jobworker.city">
        </div>

        <label class="col-md-1 col-form-label">PIN:</label>
        <div class="col-md-2">
          <input type="text" class="form-control" placeholder="Pincode" maxlength="7" v-if="jobworker" v-model="jobworker.pin">
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label">State:</label>
        <div class="col-md-4">
          <select data-placeholder="Select your state" class="form-control" v-if="jobworker" v-model="jobworker.state_cd" >
            <option value="37">Andhra Pradesh</option>
            <option value="35">Andaman and Nicobar Islands</option>
            <option value="12">Arunachal Pradesh</option>
            <option value="18">Assam</option>
            <option value="10">Bihar</option>
            <option value="04">Chandigarh</option>
            <option value="22">Chhattisgarh</option>
            <option value="26">Dadar and Nagar Haveli</option>
            <option value="25">Daman and Diu</option>
            <option value="07">Delhi</option>
            <option value="30">Goa</option>
            <option value="24">Gujarat</option>
            <option value="06">Haryana</option>
            <option value="02">Himachal Pradesh</option>
            <option value="01">Jammu and Kashmir</option>
            <option value="20">Jharkhand</option>
            <option value="29">Karnataka</option>
            <option value="32">Kerala</option>
            <option value="31">Lakshadweep</option>
            <option value="23">Madhya Pradesh</option>
            <option value="27">Maharashtra</option>
            <option value="14">Manipur</option>
            <option value="17">Meghalaya</option>
            <option value="15">Mizoram</option>
            <option value="13">Nagaland</option>
            <option value="21">Odisha</option>
            <option value="99">Over Seas</option>
            <option value="34">Pondicherry</option>
            <option value="03">Punjab</option>
            <option value="08">Rajasthan</option>
            <option value="11">Sikkim</option>
            <option value="33" selected="">Tamil Nadu</option>
            <option value="36">Telangana</option>
            <option value="16">Tripura</option>
            <option value="09">Uttar Pradesh</option>
            <option value="05">Uttarakhand</option>
            <option value="19">West Bangal</option>
          </select>
        </div>

        <label class="col-md-1 col-form-label">Type:</label>
        <div class="col-md-2">
          <select class="form-control" v-if="jobworker" v-model="jobworker.type" >
            <option value="1" selected>Regular</option>
            <option value="2">Composite</option>
            <option value="3">Unregistered</option>
          </select>
        </div>

        <label class="col-md-1 col-form-label">GSTIN:</label>
        <div class="col-md-2">
          <input type="text" class="form-control" placeholder="GSTIN" maxlength="15" v-if="jobworker" v-model="jobworker.gstin">
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label">Opening Balance:</label>
        <div class="col-md-4">
          <input type="number"  class="form-control text-right" v-if="jobworker" v-model="jobworker.opening_balance">
        </div>
        <div class="col-md-1">
          <select id="cmbcrdr" class="form-control" v-if="jobworker"  v-model="jobworker.is_crdr" >
            <option value="1">Cr</option>
            <option value="-1">Dr</option>
          </select>
        </div>
      </div>

      <div class="text-right">
        <button type="submit" class="btn btn-primary legitRipple" @click="saveDocument">Save <i class="icon-paperplane ml-2"></i></button>
      </div>

    </div>
  </div>
</template>

<script>
import {userService} from '@/store/auth-header.js'
export default {
  name: 'JobworkerForm',
  data() {
    return {
      jobworker: JSON.parse('{"id":0,"status":"","type":0,"name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0}')
    }
  },
  component: {

  },
  props: {
    myjobworker: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"status":"","type":0,"name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0}')
    }
  },
  beforeMount () {
    this.jobworker = this.myjobworker; // save props data to itself's data and deal with tms
    this.jobworker.is_crdr = (this.jobworker.opening_balance > 0 ? 1 : -1);
    this.jobworker.opening_balance = Math.abs(this.jobworker.opening_balance);
  },
  created () {
    this.$data.jobworker = JSON.parse('{"id":0,"status":"","type":0,"name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0}')
  },
  mounted () {
    $('.form-control-select2').select2()
    $('#txtname').focus();
  },
  methods: {
    closeWindow(){
      this.$parent.$parent.closeModal();
    },
    saveDocument(){

      const self = this;

      self.$data.jobworker.type = parseInt(self.$data.jobworker.type);
      if(self.$data.jobworker.name.toString().length< 5){
        alert("Invalid Name");
        return;
      }else if( parseInt(self.$data.jobworker.state_cd) < 1 ){
        alert("Invalid State selected");
        return;
      }

      self.$data.jobworker.opening_balance =  $('#cmbcrdr').val() *  Math.abs(parseFloat(self.$data.jobworker.opening_balance));

      self.$data.jobworker.state_cd = parseInt(self.$data.jobworker.state_cd);
      const requestOptions = {
        method:  (self.$data.jobworker.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.jobworker)
      };

      fetch(`${process.env.VUE_APP_ROOT_API}api/jobworker`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          swal ( { title: "Success!" ,  text: resp.msg, type:  "success"} );
          self.$emit('jobworker_saved', resp.data);
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });
    }
  }
}
</script>

<style scoped>

</style>
